import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { JobList } from '../components/careers';
import { Layout, SliceZone } from '../components';

class Careers extends Component {
  render() {
    const {
      data: {
        site: {
          siteMetadata,
        },
        page: {
          data: pageData,
        },
        jobs = [],
      },
      location,
    } = this.props;
    const {
      careersSlug,
    } = siteMetadata;
    const {
      metaTitle,
      metaDescription,
      openGraphImage,
      body,
    } = pageData;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    return (
      <Layout location={location} seoData={seoData}>
        <SliceZone
          allSlices={body}
          siteMetadata={siteMetadata}
          location={location}
        />
        <JobList
          jobs={jobs}
          careersSlug={careersSlug}
        />
      </Layout>
    );
  }
}

export default Careers;

export const pageQuery = graphql`
  query CareersQuery {
    site {
      siteMetadata {
        careersSlug,
      }
    },
    page: prismicCareers {
      uid,
      data {
        metaTitle: meta_title {
          html
          text
        },
        metaDescription: meta_description {
          html
          text
        },
        openGraphImage: open_graph_image {
          alt
          copyright
          url
        }
        body {
          ... on PrismicCareersBodyPageHero {
            id
            sliceType: slice_type
            primary {
              headline {
                text
              }
              subtitle {
                text
              }
              illustrationStyle: illustration_style
              illustrator {
                document {
                  ... on PrismicArtist {
                    data {
                      name {
                        text
                      }
                    }
                  }
                }
              }
              description {
                text
              }
              illustration {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
              theme
            }
          }
        }
      }
    }
    jobs: allPrismicJobListing {
      edges {
        node {
          data {
            jobTitle: job_title {
              text
            }
            jobType: job_type
          }
          uid
        }
      }
    }
  }
`;
